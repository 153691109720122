































































import Vue from 'vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { AmountLtoU } from '@/utils';
import { RefreshTreeFrom } from '@/interface/product';

export default Vue.extend({
  name: 'adjustmentSuccess',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    statusType: {
      type: Number,
      default: 1, // 1 组价 2 调价
    },
    price: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      adjustmentVisible: false,
    };
  },
  computed: {
    ...mapGetters(['projectBidSequenceNbr', 'projectName', 'tenderTotal']),
  },
  methods: {
    AmountLtoU,
    ...mapActions(['getProjectDetail']),
    ...mapMutations(['SET_RefreshTree', 'SET_TOSEEREPORTFORMS']),
    goAdjustment() {
      this.$emit('goAdjustment');
    },
    /**
     * 暂不调价
     */
    noHandle() {
      if (this.statusType === 1) {
        this.$emit('update:visible', false);
        this.getProjectDetail();
        this.SET_TOSEEREPORTFORMS(true);
        this.SET_RefreshTree(RefreshTreeFrom.COMBINED_PRICE);
      } else {
        this.$emit('update:visible', false);
        this.getProjectDetail();
        this.SET_TOSEEREPORTFORMS(true);
        this.SET_RefreshTree(RefreshTreeFrom.ADJUSTMENT_MERGE_SUCCESS);
      }
    },
  },
});
